import React from "react";

function Footer() {
    return (
  <div class="text-center p-3" style={{ backgroundColor:'#211c1c', color:'white' }}>
    © 2020 Copyright:
    <a class="text-body" href="https://mdbootstrap.com/">MDBootstrap.com</a>
  </div>
    );
  }
  
  export default Footer;
  