import React from "react";

function HindiReviews(){
    return (
        <div>
            <h1>Welcome to Hindi Movie Reviews</h1>
        </div>
    )
}

export default HindiReviews